import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function CasesPage() {
    const uberImg = require("./images/uber-thumbnail.png")
    const microsoftImg = require("./images/microsoft-thumbnail.png")
    const navigate = useNavigate();

    const navToPage = (path: string) => {
        navigate(path)
        window.scrollTo(0, 0);
      }


  return (
    <div className="container">
        <div className="grid mb-5">
            {/* <div className="card" onClick={() => null}>
                <div className="w-100">
                <img className="img-fluid" src={blankImg} alt="Blank Thumbnail"></img>
                </div>
                <div className="card-body text-left">
                <h5 className="card-heading">Coming Soon...</h5>
                </div>
            </div> */}

            <div className="card" onClick={() => navToPage("/cases/microsoft")}>
                <div className="w-100">
                <img className="img-fluid" src={microsoftImg} alt="Blank Thumbnail"></img>
                </div>
                <div className="card-body text-left">
                <h5 className="card-heading">Case Study: Microsoft</h5>
                <p className="card-subheading">Why are people disappointed by Copilot for Microsoft 365?</p>
                </div>
            </div>

            <div className="card" onClick={() => null}>
                <div className="w-100">
                <img className="img-fluid" src={uberImg} alt="Uber Thumbnail"></img>
                </div>
                <div className="card-body text-left">
                <h5 className="card-heading">Case Study: Uber {'(coming soon...)'}</h5>
                <p className="card-subheading">How can Uber be improved from a driver's perspective?</p>
                </div>
            </div>

        </div>
  </div>
  )
}
