import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SpotiduoPage from './pages/spotiduo/SpotiduoPage';
import Navbar from './components/Navbar';
import WardrobyPage from './pages/wardroby/WardrobyPage';
import HomePage from './pages/home/HomePage';
import AboutPage from './pages/about/AboutPage';
import MicrosoftPage from './pages/microsoft/MicrosoftPage';


function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            {/* <Route path="/about" element={<AboutPage/>}/> */}
            <Route path="/projects/spotiduo" element={<SpotiduoPage/>} />
            <Route path="/projects/wardroby" element={<WardrobyPage/>} />
            <Route path='/cases/microsoft' element={<MicrosoftPage/>} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
