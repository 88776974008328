import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    
        <footer className="container py-5">
            <div className="row">
                <div className="col-sm-8 text-left">
                    <p className="mb-1">
                        ©{' '}
                        {new Date().getFullYear()}
                        {' '}Coded with {'<3'}
                    </p>
                </div>
                <div className="col-sm-4 text-right">
                    <a className="fa fa-envelope social-icon" href="mailto:hsuan-ling_chen@brown.edu" target="_blank" aria-hidden="true"></a>
                    <a className="fa fab fa-github social-icon" href="https://github.com/chen-charlene" target="_blank" aria-hidden="true"></a>
                    <a className="fa fa-linkedin social-icon" href="https://linkedin.com/in/chen-charlene" target="_blank" aria-hidden="true"></a>
                </div>
            </div>
        </footer>
    
  )
}
