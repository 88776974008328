import React, { useEffect, useState } from 'react'
import { scrollToSection } from './CustomMethods'
import "./Sidebar.css"

export default function Sidebar() {

    const [currElement, setCurrElement] = useState<HTMLElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
        if (currElement) {
            currElement.classList.remove('sidebar-highlight');
          }
        const clickedElement = event.currentTarget as HTMLElement
        clickedElement.classList.add('sidebar-highlight')
        setCurrElement(clickedElement)
        scrollToSection(id)
    }

    const handleScroll = () => {
      const sections = [
        's1', 
        's2', 
        's3', 
        's4', 
        's5', 
        's6', 
        's7'
      ]
      let currSelection = '';
      for (let i = 0; i<sections.length; i++) {
        const section = document.getElementById(sections[i]);
        const rect = section?.getBoundingClientRect();
        if (rect && rect.top >= 0 && rect.top <= window.innerHeight / 2) {
          currSelection = sections[i];
          break;
        }
      }

      const currLink = document.querySelector(`a.anchor[id="a${sections.indexOf(currSelection) + 1}"]`) as HTMLElement
      if (currLink && currLink != currElement) {
        if (currElement) {
          currElement.classList.remove('sidebar-highlight');
        }
        currLink.classList.add('sidebar-highlight');
        setCurrElement(currLink)
      }
    }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [currElement])


  return (
    <div className="sidebar">
        <div className="space-bottom">
            <a href="/" className="anchor">← Home</a>
        </div>
        <div id="tags">
            <a className="anchor" id="a1" onClick={(e) => handleClick(e, 's1')}>Overview</a>
            <a className="anchor" id="a2" onClick={(e) => handleClick(e, 's2')}>Problem</a>
            <a className="anchor" id="a3" onClick={(e) => handleClick(e, 's3')}>Research</a>
            <a className="anchor" id="a4" onClick={(e) => handleClick(e, 's4')}>Project Goals</a>
            <a className="anchor" id="a5" onClick={(e) => handleClick(e, 's5')}>Final Product</a>
            <a className="anchor" id="a6" onClick={(e) => handleClick(e, 's6')}>Process</a>
            <a className="anchor" id="a7" onClick={(e) => handleClick(e, 's7')}>Next Steps</a>
        </div>
    </div>
  )
}
