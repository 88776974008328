import { useCallback } from 'react';

export const scrollToSection = (id: string) => {
    const header = document.querySelector('top');
    const element = document.getElementById(id);
    const offset = header ? header.clientHeight : 0; // Includes padding

    if (element) {
        const top = element.getBoundingClientRect().top + window.scrollY - (offset + 100);
        window.scrollTo({ top, behavior: 'smooth' });
    }
}

