import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar'
import CasesPage from '../cases/CasesPage';
import ProjectsPage from '../projects/ProjectsPage'


export default function HomePage() {
  const me1Img = require('./images/me1.png');
  const me2Img = require('./images/me2.png');
  const humanHandImg = require('./images/human-hand.png');
  const robotHandImg = require('./images/robot-hand.png');
  const chubImg = require('./images/me-chubs.png');
  const endingImg = require('./images/ending.png');
  const linked1Img = require('./images/linkedin1.png')
  const linked2Img = require('./images/linkedin2.png')
  const [isHover, setIsHover] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  const maxLeftPosition = window.innerWidth * 0.5; 
    const maxRightPosition = window.innerWidth * 0.5; 

    const calculateLeftPosition = () => {
        return Math.min(scrollPosition * 0.5, maxLeftPosition);
    };

    const calculateRightPosition = () => {
        return Math.min(scrollPosition * 0.5, maxRightPosition);
    };


  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div className="container">
      <Navbar/>
      
      <div className="col space-top">
        <div className="row d-flex justify-center">
          <div className="col-lg-8 space-lg no-space-bottom">
            <div className="row justify-center">
              <div className="col-md-5" onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)}>
                <figure>
                  {
                    isHover? (
                      <img src={me2Img} alt="Me holding a PB toast!" className="img-fluid"></img>
                    ) : (
                      <img src={me1Img} alt="Me holding a banana!" className="img-fluid"></img>
                    )
                  }
                </figure>
              </div>
              <div className="col-md-7 space-sm text-left">
                <h2>Hi there - i'm Charlene!</h2>
                <p className="proj-body">
                  I am a junior studying Computer Science at Brown University. 
                  I am passionate about leveraging technology to solve problems, human-centered design, & crafting fun experiences.
                </p>
                <p className="proj-body">
                  Make yourself comfortable & enjoy a PB & banana toast while you’re here :{`)`}
                </p>
                <div className="row d-flex justify-right">
                  <div className="col-sm-4 text-right">
                      <a className="fa fa-envelope social-icon" href="mailto:hsuan-ling_chen@brown.edu" target="_blank" aria-hidden="true"></a>
                      <a className="fa fab fa-github social-icon" href="https://github.com/chen-charlene" target="_blank" aria-hidden="true"></a>
                      <a className="fa fa-linkedin social-icon" href="https://linkedin.com/in/chen-charlene" target="_blank" aria-hidden="true"></a>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div id="work" className="d-flex justify-center space-md no-space-bottom">
            <ProjectsPage/>
          </div>
          
          <div className="hand-container">
            <div className="row">
              <div className="col" style={{ position: 'absolute', left: calculateLeftPosition(), zIndex: 50}}>
                <figure>
                  <img src={humanHandImg} alt="Human Hand image" className="img-fluid human-hand"></img>
                </figure>
              </div>
              <div className="col" style={{ position: 'absolute', right: calculateRightPosition(), zIndex: 50}}>
                <figure>
                  <img src={robotHandImg} alt="Robot Hand image" className="img-fluid robot-hand"></img>
                </figure>
              </div>
            </div>
           </div>

          <div className="d-flex justify-center space-md no-space-top">
            <div className="row d-flex justify-center align-items-center space-lg no-space-top">
                  <div className="col-md-3">
                    <img className="img-fluid" src={chubImg}></img>
                  </div>
                  
                  <div className="col-md-5 proj-body text-left">
                      <h4>I am driven by a love for memorable experiences that happen at the intersection of humans, technology, & design.</h4>
                      <h6 className="text-right">... and of course my pet bunny Chubs</h6>
                  </div>
              </div>
          </div>


          <div id="cases" className="d-flex justify-center space-lg no-space-bottom">
            <div className="col">
              <h3 className="container w-100 space-top ">A few shower thoughts...</h3>
              <CasesPage/>
            </div>
          </div>

          <div id="contacts" className="d-flex justify-center space-top-sm">
            <div className="row justify-center">
              <div className="col-md-5">
                  <img src={endingImg} alt="Call to action image" className="img-fluid"></img>
              </div>
              <div className="col-md-3 d-flex align-items-center justify-center">
                <figure>
                  <a href="https://linkedin.com/in/chen-charlene" target="_blank" onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)}>
                    {
                      isHover? (
                        <img src={linked2Img} alt="Linkedin Icon 2" className="img-fluid"></img>
                      ) : (
                        <img src={linked1Img} alt="Linkedin Icon 1" className="img-fluid"></img>
                      )
                    }
                  </a>
                 
                </figure>
              </div>
            </div>
          </div>
        </div>
    
      </div>

      <Footer/>


      
    </div>
  )
}
