import React from 'react'
import { scrollToSection } from '../../components/CustomMethods'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/Sidebar'
import '../styles.css'

export default function WardrobyPage() {
    const problemImg = require('./images/problem.png')
    const final1Img = require('./images/final1.png')
    const final2Img = require('./images/final2.png')
    const final3Img = require('./images/final3.png')
    const final4Img = require('./images/final4.png')
    const pie1Img = require('./images/pie1.png')
    const pie2Img = require('./images/pie2.png')
    const wheringImg = require('./images/wheringlogo.png')
    const psImg = require('./images/pslogo.png')
    const drestImg = require('./images/drestlogo.png')
    const algoImg = require('./images/algo.png')
    const hultImg = require('./images/hult.png')

  return (
    <div>
        <div className="top"></div>
        <div className="container">
            <Navbar/>
            <div className="row">
                <div className="col-lg-2">
                    <Sidebar/>
                </div>
                <div className="col-lg-10 ml-auto col-sm-12">

                    {/* Introduction */}
                    <div className="space-md side-padding-sm">
                        <div className="headline-content">
                            <h1>Wardroby</h1>
                            <h1 className="subtitle">A personalized outfit recommendation application</h1>
                        </div>

                        <div className="row d-flex align-content-center space-top proj-body justify-center">
                            <div className="col-md-3">
                                <div className="text-small"><strong>role</strong></div>
                                <p>Tech Lead</p>
                                <p>Business Lead</p>
                            </div>
                            <div className="col-md-3">
                                <div className="text-small"><strong>timeline</strong></div>
                                <p>Jan 2023</p>
                            </div>
                            <div className="col-md-3">
                                <div className="text-small"><strong>team</strong></div>
                                <p>
                                    <a className="link" href="https://www.linkedin.com/in/jesse-qin-3b923a252/">Jesse Qin</a>
                                </p>
                                <p>
                                    <a className="link" href="https://www.linkedin.com/in/thomas-dimayuga-596345268/">Thomas Dimayuga</a>
                                </p>
                                <p>
                                    <a className="link" href="https://www.linkedin.com/in/alec-lippman/">Alec Lippman</a>
                                </p>
                                <p>
                                    <a className="link" href="https://www.linkedin.com/in/sophia-cheng-428a95259/">Sophia Cheng</a>
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Decorative Image */}
                    {/* <figure>
                        <img src={lofiImg} alt="Spotiduo Problem Infograph" className="img-fluid space-bottom"></img>
                    </figure> */}

                    {/* Overview */}
                    <div className="row justify-content-start space-lg no-space-top">
                        <div className="col-lg-8 mx-auto text-left">
                            <h1 id="s1" className="section">Overview</h1>
                            <div className="proj-body">We all need to get dressed every morning - but many folks encounter 
                            the challenge of not knowing what to wear despite having a large closet. This often results in 
                            splurging on new clothes unnecessarily, which not only adds to the clutter but also harms the environment. </div>
                            <div className="proj-body">Recognizing this familiar dilemma, we seized an opportunity to address it. We aimed to 
                            provide a solution that simplifies wardrobe decisions, reducing the need for excessive shopping while 
                            promoting eco-friendly fashion choices.</div>
                            <div className="proj-body">We began this project at the 2023 Hult Prize, an international competition for $1 million to fund a
                            business idea that addresses social issues. Our pitch got <strong>selected as a Summit finalist from 10,000 teams!</strong></div>
                            <a onClick={() => scrollToSection('s5')}>
                                <button className="proj-btn">
                                    See the Final Product!
                                </button>
                            </a>
                        </div>
                    </div>

                    {/* Problem Image */}
                    <figure>
                        <img id="s2" src={problemImg} alt="Spotiduo Problem Infograph" className="img-fluid"></img>
                    </figure>

                    {/* Problem Statment */}

                    <div className="space-md text-left">
                        <div className="row d-flex proj-body justify-center">
                            <div className="col-md-3 text-center"><h3>Problem</h3></div>
                            <div className="col-md-6"><p>Many individuals face the recurring issue of wardrobe indecision, 
                                leading to <strong>excessive clothing purchases and environmental strain</strong>.</p></div>
                        </div>
                        <div className="row d-flex proj-body justify-center">
                            <div className="col-md-3 text-center"><h3>Objective</h3></div>
                            <div className="col-md-6"><p>Our goal is to alleviate this dilemma by providing a solution that 
                                streamlines wardrobe decisions and promotes sustainable fashion practices</p></div>
                        </div>
                        <div className="row d-flex proj-body justify-center">
                            <div className="col-md-3 text-center"><h3>Outcome</h3></div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col">
                                        <h3 style={{marginBottom: 0}}>
                                            <i className="fas fa-user icon-sm" aria-hidden="false"></i>
                                            6000+
                                        </h3>
                                        <p>potential users</p>
                                    </div>
                                    <div className="col">
                                         <h5 style={{marginBottom: 0}}>
                                            Empower informed
                                        </h5>
                                        <h5 style={{marginBottom: 0}}>
                                            clothing consumption
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Research */}
                    <div className="row space-sm text-left">
                        <div className="col-lg-8 mx-auto">
                            <h1 id="s3" className="section">Research</h1>
                        </div>
                    </div>
                    <div className="row text-left">
                        {/* Interviews */}
                        <div className="col-lg-8 mx-auto">
                            <h2>Interviews</h2>
                            <p>During our research phase, we analyzed our survey results from <strong>100 university students</strong> and conducted 
                                in-depth interviews with individuals at fashion-related events such as {' '}
                                <a href="https://www.fashionatbrown.net/" className="link">Fashion@Brown</a>
                                {' '}and{' '}
                                <a href="https://www.niccolocasas.com/BODYSCAPES-1" className="link">RISD Bodyscapes</a>.
                            </p>

                            <div className="proj-body space-sm">
                                <p className="space-bottom-sm">Below are some interesting findings from our survey...</p>
                                <div className="row background-box mb-3">
                                    <div className="col-11 mx-auto">
                                        <ul className="nav nav-tabs space-top-sm" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pie1-tab" data-bs-toggle="tab" data-bs-target="#pie1" type="button" role="tab" aria-controls="pie1" aria-selected="true">Pie Chart 1</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pie2-tab" data-bs-toggle="tab" data-bs-target="#pie2" type="button" role="tab" aria-controls="pie2" aria-selected="false">Pie Chart 2</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="pie1" role="tabpanel" aria-labelledby="pie1-tab">
                                                <figure>
                                                    <img src={pie1Img} alt="Pie Chart Image" className="img-fluid"></img>
                                                </figure>
                                            </div>
                                            <div className="tab-pane fade" id="pie2" role="tabpanel" aria-labelledby="pie2-tab">
                                                <figure>
                                                    <img src={pie2Img} alt="Pie Chart Image" className="img-fluid"></img>
                                                </figure>
                                            </div>                                        
                                        </div>
                        
                                    </div>
                                </div>
                            </div>
                           
                           
                           
                            <div className="proj-body">
                                 <div className="row space-sm">
                                    <h3>User pain points</h3>
                                    <div className="col-md-6 space-sm side-padding-smallest">
                                        <h5>😣 Frustration when pairing outfits</h5>
                                        <p>Participants feel overwhelmed by the multitude of options in their wardrobe 
                                            and the lack of a systematic approach to curating outfits</p>
                                    </div>
                                    <div className="col-md-6 space-sm side-padding-smallest">
                                        <h5>❌ Lack of avenues for reusing old clothing</h5>
                                        <p>Participants expressed a desire to extend the lifespan of their garments and 
                                            reduce waste but often felt limited in their ability to do so.</p>
                                    </div>
                                    <div className="col-md-6 space-sm side-padding-smallest no-space-top">
                                        <h5>💸 Wasting money</h5>
                                        <p>Frustration with the cycle of purchasing new clothing only to wear it once 
                                            or twice, leading to a sense of financial strain and dissatisfaction.</p>
                                    </div>
                                 </div>
                            </div>
                        </div>

                        {/* Market Research */}
                        <div className="col-lg-8 mx-auto">
                            <h2>Competitive Analysis</h2>
                            <p>We analyzed the drawbacks of existing outfit styling platforms to identify their strengths and weaknesses to help inform our product</p>
                            <div className="proj-body space-sm">
                                <div className="row">
                                    <div className="col-md-2 d-flex side-padding-smallest no-space-top">
                                    </div>
                                    <div className="col-md-5 d-flex side-padding-smallest no-space-top">
                                        <h4>Strengths</h4>
                                    </div>
                                    <div className="col-md-5 d-flex side-padding-smallest no-space-top">
                                        <h4>Weaknesses</h4>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-md-2 d-flex align-items-center space-sm side-padding-smallest no-space-top">
                                        <figure>
                                            <img src={wheringImg} alt="Whering Logo" className="img-fluid"></img>
                                        </figure>
                                    </div>
                                    <div className="col-md-5 space-sm side-padding-smallest no-space-top">
                                        <p>
                                            <ul>
                                                <li>Wardrobe digitization</li>
                                                <li>Outfit planning and organization</li>
                                                <li>Styling suggestions</li>
                                                <li>Item Recommendations</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="col-md-5 space-sm side-padding-smallest no-space-top">
                                        <p>
                                            <ul>
                                                <li>Lack of human touch</li>
                                                <li>Lack of real-time styling advice</li>
                                            </ul>
                                        </p>
                                    </div>
                                 </div>

                                 <div className="row">
                                    <div className="col-md-2 d-flex align-items-center space-sm side-padding-smallest no-space-top">
                                        <figure>
                                            <img src={drestImg} alt="PS Logo" className="img-fluid"></img>
                                        </figure>
                                    </div>
                                    <div className="col-md-5 space-sm side-padding-smallest no-space-top">
                                        <p>
                                            <ul>
                                                <li>Engaging gamification</li>
                                                <li>Avatar customization</li>
                                                <li>Daily styling challenges</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="col-md-5 space-sm side-padding-smallest no-space-top">
                                        <p>
                                            <ul>
                                                <li>Lack of real-world application</li>
                                                <li>Limited personalization</li>
                                            </ul>
                                        </p>
                                    </div>
                                 </div>

                                 <div className="row">
                                    <div className="col-md-2 d-flex align-items-center space-sm side-padding-smallest no-space-top">
                                        <figure>
                                            <img src={psImg} alt="PS Logo" className="img-fluid"></img>
                                        </figure>
                                    </div>
                                    <div className="col-md-5 space-sm side-padding-smallest no-space-top">
                                        <p>
                                            <ul>
                                                <li>Virtual style consultations</li>
                                                <li>Product recommendations</li>
                                                <li>Mood boards</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="col-md-5 space-sm side-padding-smallest no-space-top">
                                        <p>
                                            <ul>
                                                <li>Limited scalability</li>
                                                <li>Reliance on stylist availability</li>
                                            </ul>
                                        </p>
                                    </div>
                                 </div>
                            </div>

                            <div className="proj-body">
                                 <div className="row space-sm">
                                    <h3>Unfilled gaps in current solutions</h3>
                                    <div className="col-md-6 space-sm side-padding-smallest">
                                        <h5>😥 Polarizing degrees of personalization</h5>
                                        <p>Algorithmic recommendations might lack the human touch and understanding of 
                                            individual preferences, while human recommendations can be subjective and 
                                            dependent on stylist availability.</p>
                                    </div>
                                    <div className="col-md-6 space-sm side-padding-smallest">
                                        <h5>❌ Limited integration with external platforms</h5>
                                        <p>Integrating with external platforms or services (e.g., e-commerce platforms, social media) is limited.</p>
                                    </div>
                                    <div className="col-md-6 space-sm side-padding-smallest no-space-top">
                                        <h5>⌛️ Limited real time interaction & feedback</h5>
                                        <p>Can be crucial for users who seek instant feedback or assistance during the styling process.</p>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>

                    {/* Project Goals */}
                    <div className="row space-sm text-left">
                        <div className="col-lg-8 mx-auto">
                            <h1 id="s4" className="section">Project Goals</h1>
                        </div>
                    </div>
                    <div className="space-md text-left no-space-top">                    
                        <div className="row d-flex proj-body justify-center space-sm no-space-top">
                            <div className="col-md-8">
                                <div className="col">
                                    <div className="col background-box">
                                        <h5 style={{marginBottom: 0}}>
                                            1. Save Time:
                                        </h5>
                                        <p>Streamline the outfit pairing process in a way that saves the user unnecessary time and energy</p>
                                    </div>
                                    <div className="col background-box">
                                        <h5 style={{marginBottom: 0}}>
                                            2. Enhance Wardrobe Management:
                                        </h5>
                                        <p>Develop a solution that helps users effectively manage their wardrobes, simplify outfit pairing, and optimize their clothing selections</p>
                                    </div>
                                    <div className="col background-box">
                                        <h5 style={{marginBottom: 0}}>
                                            3. Promote Sustainable Fashion Practices:
                                        </h5>
                                        <p>Encourage users to adopt sustainable fashion habits by providing avenues for reusing old clothing, reducing waste, and making mindful purchasing decisions</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-8 mx-auto space-sm">
                            <h2 className="section">Minimum Viable Product (MVP)</h2>
                            <div className="proj-body">
                                <p>For a successful pilot run of the mobile application, the following user stories are required:</p>
                                <div className="row d-flex space-top-sm">
                                    <div className="col-md-3 background-box text-center align-self-center">
                                        <h3 style={{marginBottom: 0}}>
                                            <i className="fas fa-user fa-3x space-bottom-sm space-top-sm side-padding-sm" aria-hidden="false"></i>
                                        </h3>
                                        <p>"As a user of Wardroby, I want to..."</p>
                                    </div>
                                    <div className="col">
                                        <ul className="proj-body">
                                            <li>
                                                easily upload photos and descriptions of my clothing items, 
                                                so that I can digitize my wardrobe for efficient organization and planning.
                                            </li>
                                            <li>
                                                receive personalized outfit suggestions based on my clothing preferences, occasion, 
                                                and weather conditions, so that I can effortlessly plan and coordinate my outfits.
                                            </li>
                                            <li>
                                                explore creative ways to reuse or restyle old clothing items, so that I can extend 
                                                their lifespan and reduce waste.
                                            </li>
                                                
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 mx-auto space-sm">
                            <h2 className="section">Potential Solutions</h2>
                            <div className="proj-body">
                                One of the biggest drawbacks of existing platforms is how they personalize the recommendations, 
                                fully algorithmic approach has issues with extent of personalization, fully human stylist has 
                                issues with scalability and availability.
                            </div>
                            <div className="row d-flex proj-body justify-center">
                                <div className="col-md-3 text-left"><h5>Hybrid approach</h5></div>
                                <div className="col"><p>Combine the strengths of both algorithmic and human recommendation 
                                    approaches to provide users with a comprehensive and personalized styling experience</p></div>
                            </div>
                            <div className="row d-flex proj-body justify-center">
                                <div className="col-md-3 text-left"><h5 style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>Transparent Recommendation Process</h5></div>
                                <div className="col"><p>Provide users with insights into how outfit suggestions are generated 
                                    and allow them to finetune parameters, gives the user control over their preferences.</p></div>
                            </div>
                        </div>

                        <div className="col-lg-8 mx-auto space-sm">
                            <h2 className="section">Final Solution & Launch Strategy</h2>
                            <div className="proj-body">
                                After evaluating the benefits and drawbacks, we decided on adopting a progressive integration of 
                                AI in styling recommendations. This will be incorporated into the service in a 3 phase roll out:
                            </div>
                            <div className="row d-flex proj-body justify-center">
                                <div className="col text-left background-box"><strong>1. Human Stylist Recommendations</strong></div>
                                <div className="col text-left background-box"><strong>2. Hybrid Approach</strong></div>
                                <div className="col text-left background-box"><strong>3. AI-Driven Recommendations</strong></div>
                            </div>
                            <div className="row d-flex proj-body justify-center">
                                <div className="col-md-3 text-left space-top-sm"><h5>Benefits</h5></div>
                                <div className="col text-left space-top-sm">
                                    <ul>
                                        <li><strong>Scalability:</strong> The progressive integration of AI allows the platform to scale efficiently 
                                            to accommodate a growing user base without compromising the quality of recommendations.
                                        </li>
                                        <li className="space-top-sm">
                                            <strong>Quality Assurance: </strong>Starting with human stylist recommendations ensures the initial quality 
                                            of recommendations, while the gradual transition to AI-driven recommendations ensures consistent quality 
                                            and relevance throughout the platform's evolution
                                        </li>
                                    </ul>
                                
                                </div>
                            </div>
                            
                        </div>


                    </div>

                    {/* Final Product */}
                    <div className="row space-sm text-left">
                        <div className="col-lg-8 mx-auto">
                            <h1 id="s5" className="section">Final Product</h1>
                            <a onClick={() => scrollToSection('s6')}>
                                <button className="proj-btn">
                                    Click here to read about our process!
                                </button>
                            </a>
                        </div>
                        <figure>
                            <img src={final1Img} alt="Final Product gif" className="img-fluid space-sm"></img>
                            <img src={final2Img} alt="Final Product gif" className="img-fluid space-sm"></img>
                            <img src={final3Img} alt="Final Product gif" className="img-fluid space-sm"></img>
                            <img src={final4Img} alt="Final Product gif" className="img-fluid space-sm"></img>                        </figure>
                        
                    </div>

                    {/* Process */}
                    <div className="row space-sm text-left">
                        <div className="col-lg-8 mx-auto">
                            <h1 id="s6" className="section">Process</h1>
                        </div>
                    </div>
                    
                    {/* Revenue Model */}
                    <div className="space-md text-left no-space-bottom">
                        <div className="row d-flex proj-body justify-center">
                            <div className="col-lg-8 text-left">
                                <h2>Revenue Model</h2>
                            </div>        
                        </div>
                    </div>
                    {/* <div className="col-lg-8"> */}
                        <div className="row d-flex proj-body justify-center">
                            <div className="col-lg-8 text-left"><p>We proposed a two-fold business model to generate revenue</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-center">
                            <div className="col-lg-9">
                                <div className="row d-flex proj-body justify-center">
                                    <div className="col-md-3 text-left"><h4>Premium Version:</h4></div>
                                    <div className="col-md-8 text-left"><p>Offers expanded virtual wardrobe space and advanced suggestions, including accessories, shoes, hats, and hairstyles.</p></div>
                                </div>
                                <div className="row d-flex proj-body justify-center">
                                    <div className="col-md-3 text-left"><h4>Commission-Based:</h4></div>
                                    <div className="col-md-8 text-left"><p>Earns a 10% commission on user-sold clothing (suggested by our algorithm) and a 15% commission on purchases from partnered slow fashion platforms.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                    {/* </div> */}
                    

                    <div className="col-lg-8 mx-auto space-lg no-space-bottom text-left">
                        <h2>Technical Implementation</h2>
                        <div className="space-sm">
                            <figure>
                                <img src={algoImg} alt="algorithm flow chart" className="img-fluid space-bottom-sm"></img>
                            </figure>
                            <div className="proj-body">
                            The flowchart illustrates our proposed algorithm's implementation. Users will scan their clothing into the database,
                             where image recognition identifies features like color and texture. When users request outfits, the algorithm uses AI 
                             and machine learning to suggest outfits based on their preferences. User feedback will be collected to finetune the AI
                              model to allow increasingly precise generations.
                            </div>
                        </div>  
                    </div>

                    
                    {/* Decorative Image */}
                    <figure>
                        <img src={hultImg} alt="Hult image" className="img-fluid space-md"></img>
                    </figure>


                    {/* Next Steps */}
                    <div className="row space-sm text-left">
                        <div className="col-lg-8 mx-auto">
                            <h1 id="s7" className="section">Next Steps</h1>
                        </div>
                    </div>
                    
                    <div className="row text-left justify-center">
                        <div className="col-lg-8">
                            <h4>Currently Working on...</h4>
                        </div>
                        <ul className="proj-body col-lg-7">
                            <li><strong>Gathering Phase 1 Stylist Partnerships:</strong> Finding local student stylists to offer the initial service</li>
                            <li><strong>Algorithm Development:</strong> Implementing a deep learning model for outfit suggestions</li>
                            <li><strong>Initial Interface Protoype:</strong> Creating a user-friendly interface for the platform</li>
                        </ul>
                    </div>

                    <div className="row text-left justify-center">
                        <div className="col-lg-8">
                            <h4>Takeaways...</h4>
                        </div>
                        <ul className="proj-body col-lg-7">
                            <li className="proj-body"><strong>Understand Your Audience! </strong>
                                <ul>
                                    <li>Tailoring our pitch to address the specific interests and concerns of the audience, whether they are investors, potential users, or partners.</li>
                                </ul>
                            </li>
                            <li className="proj-body"><strong>Importance of Research</strong>
                                <ul>
                                    <li>Research provides data that guide strategic decisions and can mitigate risks later on</li>
                                </ul>
                            </li>
                            <li className="proj-body"><strong>Establishing Clear Value Proposition</strong>
                                <ul>
                                    <li><strong>Outline Unique Benefits:</strong> Clearly articulate the unique value and benefits your product offers to stand out from competitors</li>
                                    <li><strong>User-Centric Features:</strong> Highlight features that directly address user pain points and improve their experience.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <Footer/>
            
        </div>
    </div>
  )
}
