import React from 'react'
import { scrollToSection } from '../../components/CustomMethods'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import CaseSidebar from '../../components/CaseSidebar'
import '../styles.css'

export default function MicrosoftPage() {
    const coverImg = require("./images/copilot-cover.jpeg")
    const final1Img = require("./images/final1.png")
    const final2Img = require("./images/final2.png")
    const final3Img = require("./images/final3.png")
    const copilotFooterImg = require('./images/copilot-footer.jpeg')
    const marketImg = require('./images/market.png')
    const userImg = require('./images/userJourney.png')
    const solutionsImg = require('./images/solutions.png')

  return (
    <div>
    <div className="top"></div>
    <div className="container">
        <Navbar/>
        <div className="row">
            <div className="col-lg-2">
                <CaseSidebar/>
            </div>
            <div className="col-lg-10 ml-auto col-sm-12">

                {/* Introduction */}
                <div className="space-md side-padding-sm">
                    <div className="headline-content">
                        <h1>Enhancing the Microsoft Copilot for 365 Onboarding Experience</h1>
                    </div>
                </div>


                {/* Context */}
                <div className="row justify-content-start space-lg no-space-top">
                    <div className="col-lg-8 mx-auto text-left">
                        <h1 id="s1" className="section">Context</h1>
                        <div className="proj-body">In today's AI-driven business landscape, Microsoft's Copilot for 365 represents a shift in integrating 
                        AI directly into workspaces, unlike standalone chatbots like ChatGPT. This trend aligns with the broader adoption of AI in everyday
                         workflows. <strong> However, users may face challenges in adjusting to this transition. </strong> </div>
                        <div className="proj-body">This case study aims to <strong>identify common pain points</strong> with the current Copilot for 365 ecosystem and 
                        <strong>{" "}develop a streamlined solution to address user concerns</strong>, facilitating a smooth transition to AI-integrated workflows and maximizing 
                        the benefits of Copilot.</div>
                        <a onClick={() => scrollToSection('s4')}>
                            <button className="proj-btn">
                                See the Final Solution!
                            </button>
                        </a>
                    </div>
                </div>

                {/* Decorative Image */}
                <figure>
                    <img src={coverImg} alt="Copilot Cover Image" className="img-fluid"></img>
                </figure>


                {/* Research */}
                <div className="row space-sm text-left">
                    <div className="col-lg-8 mx-auto">
                        <h1 id="s2" className="section">Research</h1>
                    </div>
                </div>
                <div className="row text-left">
                    {/* The Audience */}
                    <div className="col-lg-8 mx-auto">
                        <h2>The Audience</h2>
                        <p>At a high level, the primary user base for Microsoft Copilot for 365 consists of <mark>enterprise customers</mark>, as the service is tightly integrated with Microsoft 365 Suite, 
                            typically offered to businesses and educational institutions. Within this user base, those most likely to subscribe to Copilot are organizations that prioritize 
                            results, productivity, and technology integration. <br/>
                            Copilot's ability to <strong>save 1.2 hours per week, increase productivity by 70%, and enhance quality by 68% </strong>makes it particularly appealing to businesses and service 
                            sectors such as government and non-profits. {"("}
                            <a href="https://www.microsoft.com/en-us/worklab/work-trend-index/copilots-earliest-users-teach-us-about-generative-ai-at-work/" className="link">Source</a>
                            {').'}
                        </p>
                        <div className="proj-body space-sm">
                            <div className="row justify-even">
                                <div className="col-md-6 d-flex side-padding-smallest no-space-top">
                                    <h4>
                                        <i className="fas fa-user icon-sm" aria-hidden="false"></i>
                                        Potential User Base
                                    </h4>
                                </div>
                                <div className="col-md-6 d-flex side-padding-smallest no-space-top">
                                    <h4>Use Cases</h4>
                                </div>
                             </div>
                             <div className="row justify-even">
                                <div className="col-md-6 side-padding-smallest no-space-top">
                                    <p>
                                        <ul>
                                            <li>Small & Medium Enterprises (SMEs)</li>
                                            <li>Large Organizations & Corporations</li>
                                            <li>Educational Institutions (faculty)</li>
                                            <li>Government & Public Sector</li>
                                            <li>Nonprofit & NGOs</li>
                                        </ul>
                                    </p>
                                </div>
                                <div className="col-md-6 side-padding-smallest no-space-top">
                                    <p>
                                        <ul>
                                            <li>Automatically generate documents based on user input or templates.</li>
                                            <li>Assisting in data analysis and interpretation</li>
                                            <li>Streamlining communication and collaboration processes, such as drafting emails, scheduling meetings, etc.</li>
                                            <li>Enhancing creativity and innovation by suggesting new ideas & solutions</li>
                                        </ul>
                                    </p>
                                </div>
                             </div>
                        </div>

                        <p className="space-sm no-space-top">
                            <h6>To summarize, we’ll be focusing on users who are:</h6>
                            <ul>
                                <li>Result-driven and focused on enhancing productivity</li>
                                <li>Technologically oriented and open to adopting AI-driven solutions</li>
                                <li>Extensive users of Microsoft Suite products for their day-to-day operations</li>
                                <li>Interested in streamlining workflows, improving efficiency, and maintaining high-quality outputs.</li>
                            </ul>
                        </p>

                       
                       
                       {/* User Insights */}
                        <div className="proj-body">
                             <div className="row space-sm">
                                <h3>User Insights</h3>
                                <div className="col-md-6 space-sm side-padding-smallest no-space-top">
                                    <h5>✅ Satisfaction with Copilot in Teams</h5>
                                    <p>Users express satisfaction with Copilot's functionality within Microsoft Teams, 
                                        particularly the recap feature, which some consider worth the investment.</p>
                                </div>
                                <div className="col-md-6 space-sm side-padding-smallest no-space-top">
                                    <h5>❌ Worse than ChatGPT</h5>
                                    <p>Users perceive minimal differentiation between Copilot and ChatGPT except for Copilot's integration 
                                        within Microsoft applications and often prefer to copy and paste content from ChatGPT due to its 
                                        superior performance</p>
                                </div>
                                <div className="col-md-6 side-padding-smallest no-space-top">
                                    <h5>❌ Disappointing Features</h5>
                                    <p>There's a prevailing impression among users that many features of Copilot, particularly in Microsoft 
                                        Office applications like Word, PowerPoint, and Excel, are underdeveloped and fail to meet expectations.</p>
                                </div>
                                <div className="col-md-6 side-padding-smallest no-space-top">
                                    <h5>❌ Difficulty Setting Up</h5>
                                    <p>Users encounter difficulties in setting up and updating Copilot across all Microsoft 365 applications, 
                                        leading to delays in utilizing the tool effectively.</p>
                                </div>
                             </div>
                        </div>
                    </div>

                    {/* User Pain Points */}
                    <div className="row d-flex proj-body justify-center space-sm no-space-top">
                        <div className="col-md-8">
                            <h3 className="no-space-top">User Pain Points</h3>
                            <div className="col">
                                <div className="col background-box">
                                    <h5 style={{marginBottom: 0}}>
                                        1. Lack of seamless integration:
                                    </h5>
                                    <p>The process of setting up and updating Copilot across various apps is cumbersome and time-consuming, delaying users from accessing and utilizing the tool effectively.</p>
                                </div>
                                <div className="col background-box">
                                    <h5 style={{marginBottom: 0}}>
                                        2. Inconsistent Performance Across Applications:
                                    </h5>
                                    <p>While some functionalities may work well in certain apps like Teams, users encounter issues with performance and functionality in others like Word, PowerPoint, and Excel.</p>
                                </div>
                                <div className="col background-box">
                                    <h5 style={{marginBottom: 0}}>
                                        3. Limited differentiation from ChatGPT:
                                    </h5>
                                    <p>Copilot fails to offer significant advantages that justify its adoption over existing alternatives, leading to skepticism and reluctance among users.</p>
                                </div>
                                <div className="col background-box">
                                    <h5 style={{marginBottom: 0}}>
                                        4. Complexity of Use and Lack of Training Resources:
                                    </h5>
                                    <p>Users face challenges and are left feeling unsupported and frustrated with the learning curve associated with the tool.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Market Research */}
                    <div className="col-lg-8 mx-auto space-sm">
                        <h2>The Market</h2>
                        <p>What are the leading AI-companion options available in the market?</p>
                        <figure>
                            <img src={marketImg} alt="Market analysis" className="img-fluid"></img>
                        </figure>
                        <div className="proj-body space-sm">
                            <h4>Summary</h4>
                            <p>
                                <ul>
                                    <li>
                                        ChatGPT excels in natural language processing but lacks productivity tool integration, while Google Workspace with Gemini offers simpler 
                                        integration but limited capabilities compared to Microsoft Copilot. <mark>Microsoft Copilot stands out as the leader in AI integration into 
                                        productivity applications.</mark> 
                                    </li>
                                    <li>
                                        However, to maximize user adoption and satisfaction, there is a need to orient users to this new way of 
                                        utilizing AI, particularly as the industry standard has primarily been interaction with chat-based AI companions like ChatGPT.
                                    </li>
                                    <li>
                                        Therefore, <mark>educating users on the benefits and potential of AI-integrated productivity tools</mark> like Microsoft Copilot becomes essential for its success in the market.
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

                {/* User Journey */}
                <div className="col-lg-8 mx-auto space-sm no-space-bottom text-left">
                        <h2 className="section">Current User Journey</h2>
                        <h5>What does the current onboarding process look like for Microsoft Copilot?</h5>
                        <p className="proj-body">
                            Since I do not have access to Microsoft Copilot for 365 at the time of writing, external sources such as user reviews, YouTube tutorials, 
                            and Microsoft’s documents were used to inform the current onboarding process. 
                        </p>
                </div> 
                <figure>
                    <img src={userImg} alt="User Journey Image" className="img-fluid"></img>
                </figure>
                <div className="col-lg-8 mx-auto text-left space-lg no-space-top">
                    <p className="proj-body">
                        The resources provided by Microsoft include a 1 hour 49 minute course called "Get Started with Copilot for Microsoft 365" 
                        and {' '}
                        <a href="https://learn.microsoft.com/en-us/copilot/microsoft-365/" className="link">Microsoft's documents</a>.
                    </p>
                </div> 


                {/* Project Goals */}
                <div className="row space-sm text-left">
                    <div className="col-lg-8 mx-auto">
                        <h1 id="s3" className="section">Problem & Goals</h1>
                    </div>
                </div>
                <div className="row d-flex proj-body justify-center text-left">
                    <h4 className="col-md-8">From this research, several conclusions emerge:</h4>
                    <p className="col-md-7">
                        <ol>
                            <li className="space-bottom-sm">
                                Microsoft Copilot for 365 represents a significant advancement in AI integration within productivity tools, setting it apart from conventional chat-based AI models. Its direct integration with Microsoft 365 Apps positions it as more than just a chat interface, functioning as a comprehensive AI assistant. <mark>Competitors currently lack a comparable product</mark> in terms of this level of integration and functionality.
                            </li>
                            <li className="space-bottom-sm">
                                Despite its promising features, there <mark>exists user dissatisfaction with Copilot's performance</mark>. Users report issues with inconsistent functionality across different Microsoft 365 applications, leading to skepticism regarding its reliability and effectiveness.
                            </li>
                            <li>
                                Users are currently <mark>struggling to fully utilize Copilot</mark> and seamlessly incorporate it into their workflow. The <mark>lack of comprehensive onboarding resources and training materials</mark> contributes to this challenge, hindering users from tapping into the tool's full potential and maximizing its benefits.
                            </li>
                        </ol>
                    </p>
                </div>
                <div className="space-md text-left no-space-top">                    
                    <div className="text-left">
                    <div className="row d-flex proj-body justify-center">
                        <div className="col-md-3 text-center"><h3>Problem</h3></div>
                        <div className="col-md-6"><p>User dissatisfaction and challenges with Microsoft Copilot for 365 highlight the need to <strong>improve performance and 
                            onboarding</strong> for enhanced user satisfaction and seamless workflow integration.</p></div>
                    </div>
                    <div className="row d-flex proj-body justify-center">
                        <div className="col-md-3 text-center"><h3>Goal</h3></div>
                        <div className="col-md-6"><p><strong>Increase customer satisfaction with Copilot’s performance and usability.</strong> <br/>Customer satisfaction can encompass different areas that the user might prioritize, such as task completion rate, time to proficiency, and quality of work produced.</p></div>
                    </div>
                    <div className="row d-flex proj-body justify-center">
                        <div className="col-md-3 text-center"><h3>Hypothesis</h3></div>
                        <div className="col-md-6">
                         If Copilot provides a <strong>better onboarding experience</strong>, then users will feel <strong>more confident</strong> in using the tool, leading to <strong>improved satisfaction and performance</strong>.

                        </div>
                    </div>
                </div>

                    {/* Solutions */}
                    <div className="row space-lg text-left">
                        <div className="col-lg-8 mx-auto">
                            <h1 id="s4" className="section">Solutions</h1>
                        </div>

                        {/* Potential Solutions */}
                        <div className="col-lg-8 mx-auto space-sm no-space-bottom">
                            <h2 className="section">Potential Solutions</h2>
                            <p>Here are some high-level features that relate to the overall solution, evaluated using the RICE framework</p>
                            <figure>
                                <img src={solutionsImg} alt="Potential solutions RICE" className="img-fluid"></img>
                            </figure> 
                        </div>
                    </div>

                    {/* Final Solution */}
                    <div className="col-lg-8 mx-auto no-space-bottom">
                        <h2 className="section">Final Solution & MVP</h2>
                        <h4 className="space-top-sm"><mark>Final Solution</mark>: Guided Prompt Generation</h4>
                        <div className="proj-body">
                            <p>
                                Microsoft has a {' '}
                                <a href="https://support.microsoft.com/en-gb/topic/learn-about-copilot-prompts-f6c3b467-f07c-4db1-ae54-ffac96184dd5" className="link">comprehensive guide</a> 
                                {' '} on prompt formulation to get the best performance out of Copilot, and it centers around the structure of Goal, Context, Expectations, and Source. 
                            </p>
                        </div>
                        <div className="proj-body">
                            <p>
                                <mark>Users accustomed to using vague prompts</mark> with ChatGPT for simple tasks like generating paragraphs may find Copilot's integrated functionality challenging due to 
                                its higher performance expectations. By <mark>emphasizing structured prompt formulation</mark>, guided prompt generation can bridge this gap, enabling users to generate 
                                <mark>higher-quality copilot responses</mark> tailored to their specific needs and tasks.
                            </p>
                        </div>
                    </div>
                    <figure>
                        <img src={final1Img} alt="Final solution" className="img-fluid space-sm"></img>
                        <img src={final2Img} alt="Final solution" className="img-fluid space-sm"></img>
                        <img src={final3Img} alt="Final solution" className="img-fluid space-sm"></img>                   
                    </figure>
                    <div className="col-lg-8 mx-auto no-space-bottom">
                        <div className="proj-body">
                            <p>By implementing these <strong>three key components</strong>, Guided Prompt Generation addresses the core challenge of bridging the gap between users' familiarity with vague prompts and Copilot's 
                                advanced capabilities. Ultimately, this solution enhances collaboration, productivity, and user satisfaction with Microsoft Copilot for 365.</p>
                        </div>
                    </div>


                </div>

                {/* Metrics */}
                <div className="row space-sm text-left">
                    <div className="col-lg-8 mx-auto">
                        <h1 id="s5" className="section">Metrics</h1>
                    </div>

                    {/* Measuring Success */}
                    <div className="col-lg-8 mx-auto space-sm no-space-top">
                        <h4 className="space-sm no-space-bottom">North Star Metric</h4>
                        <ul>
                            <li><strong>Overall user satisfaction with guided prompt generated responses</strong></li>
                            <p className="space-top-sm">It would encompass factors such as <mark>response accuracy, relevance, completeness, and ease of use</mark>, 
                            which are directly influenced by the guided prompt generation feature. This data will be primarily based on <mark>direct user feedback</mark> and supported by secondary metrics to offer more insight.</p>
                        </ul>
                        <h4 className="space-sm no-space-bottom">Secondary Metrics</h4>
                        <ul>
                            <li className="space-bottom-sm"><strong>Prompt Structure Adoption Rate:</strong> The percentage of users who start using structured prompts after being prompted for clarification</li>
                            <li className="space-bottom-sm"><strong>Real-time suggestion Acceptance Rate:</strong> The percentage of users who accept and implement real-time suggestions provided by the system</li>
                            <li className="space-bottom-sm"><strong>User Engagement with Clarifying Questions:</strong> The frequency and depth of engagement with Copilot’s clarifying questions, indicating the user’s willingness to provide additional context</li>
                            <li><strong>Quality of Prompt-generated Responses:</strong> The accuracy, relevance, and completeness of responses generated by Copilot based on user prompts</li>
                        </ul>
                        <h4 className="space-sm no-space-bottom">Counter Metrics</h4>
                        <ul>
                            <li className="space-bottom-sm"><strong>Prompt Rejection Rate:</strong>  The percentage of prompts that are rejected or dismissed by users, due to dissatisfaction with the generated prompts</li>
                            <li className="space-bottom-sm"><strong>Prompt Abandonment Rate:</strong> The percentage of users who abandon the prompt generation process before completion due to complexity or dissatisfaction</li>
                        </ul>
                    </div>

                    {/* Risks and tradeoffs */}
                    <div className="col-lg-8 mx-auto space-sm">
                        <h2 className="section">Risks and Tradeoffs</h2>
                        <ul>
                            <li className="space-bottom-sm">
                                <strong>User Adoption Challenges</strong>
                                <ul>
                                    <li>Users may initially resist the structured prompt format, preferring their accustomed free-form input. Convincing users of the benefits of structured prompts may require education and persuasion efforts</li>
                                </ul>
                            </li>
                            <li className="space-bottom-sm">
                                <strong>Accuracy or Relevance Issues</strong>
                                <ul>
                                    <li>Despite the guided prompts, users may still not see substantial improvement in the responses generated, leading to more user frustration. Continuos improvement of the model is necessary to mitigate this risk</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Feedback Overload</strong>
                                <ul>
                                    <li>Real-time suggestions and clarifying questions may overwhelm the user with feedback, causing distraction from their original task. Striking a good balance between providing helpful guidance and avoiding information overload is crucial.</li>
                                </ul>
                            </li>
                            
                        </ul>
                    </div>

                    {/* Future Iterations */}
                    <div className="col-lg-8 mx-auto space-sm no-space-bottom">
                        <h2 className="section">Future Iterations</h2>
                        <h4 className="space-top-sm">What the onboarding process will look like in the future?</h4>
                        <div className="proj-body">
                            To help users maximize Copilot for 365 and seamlessly integrate it into their workflow, they need to be familiar with the tool and be satisfied with and trust the quality of work produced. Below are some features that would complement this reimagined onboarding experience so the user can feel confident going into an AI-integrated workspace.
                        </div>
                        <ul>
                            <li className="space-bottom-sm"><strong>Interactive Tutorials and Training Programs:</strong> Develop interactive tutorials and training programs supported by Microsoft to onboard users more effectively and empower them to leverage Copilot to its fullest potential. </li>
                            <li className="space-bottom-sm"><strong>Copilot Assistance with Voice-based interactions:</strong> Users can interact with Copilot directly to better understand how to navigate the tool. Voice-based interactions can further enhance accessibility and make the collaboration more intuitive.</li>
                            <li ><strong>Continuos development of the model:</strong> Enhances accuracy and relevance of generated reponses without additional work or expectation on the user’s part.</li>
                        </ul>
                    </div>
                    
                </div>

                {/* Decorative Image */}
                <figure>
                    <img src={copilotFooterImg} alt="Copilot Concept image" className="img-fluid space-md"></img>
                </figure>


                {/* Summary */}
                <div className="row space-sm text-left">
                    <div className="col-lg-8 mx-auto">
                        <h1 id="s6" className="section">Summary</h1>
                        <div className="proj-body space-top-sm">
                            This case study addresses challenges in onboarding users to Microsoft Copilot for 365, aiming to maximize satisfaction and productivity. 
                        </div>
                        <div className="proj-body">
                            I would recommend <mark>A/B testing an MVP of Guided Prompt Generation </mark>with the goal of increasing user satisfaction with the generated responses based on the assumption that better prompt structure leads to better model performance. 
                        </div>
                        <div className="proj-body">
                            Overall, the solution aims to empower users to leverage Copilot effectively, driving productivity and success in Microsoft 365 environments.
                        </div>
                        <h4>Thank you for checking out this case study!</h4>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer/>
        
    </div>
</div>
  )
}
