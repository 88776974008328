import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom';

export default function ProjectsPage() {

  const wardrobyImg = require('./images/wardroby-thumbnail.png')
  const spotiImg = require("./images/spotiduo-thumbnail.png")
  const blankImg = require("./images/blank-image.png")
  const modularImg = require('./images/modular-thumbnail.png')
  const aeroImg = require('./images/aero-thumbnail.png')
  const navigate = useNavigate();
  const sectionRef = useRef(null)

  const navToPage = (path: string) => {
    navigate(path)
    window.scrollTo(0, 0);
  }

  return (
    <div className="container">
      <div className="grid mb-5">

        <div className="card" onClick={() => navToPage("/projects/spotiduo")}>
          <div className="w-100">
          <img className="img-fluid" src={spotiImg} alt="Spotify Thumbnail"></img>
          </div>
          <div className="card-body text-left">
            <h5 className="card-heading">Spotiduo Language Learning</h5>
            <p className="card-subheading">Helping users learn new languages through songs</p>
          </div>
        </div>

        <div className="card" onClick={() => navToPage("/projects/wardroby")}>
          <div className="w-100">
          <img className="img-fluid" src={wardrobyImg} alt="Wardroby Thumbnail"></img>
          </div>
          <div className="card-body text-left">
            <h5 className="card-heading">Wardroby</h5>
            <p className="card-subheading">Generate outfits based on your existing wardrobe</p>
          </div>
        </div>
        <div className="card" onClick={() => { window.open('https://github.com/allenwang1536/aero-forecast.git', '_blank')}
}>
          <div className="w-100">
          <img className="img-fluid" src={aeroImg} alt="Aeroforecase Thumbnail"></img>
          </div>
          <div className="card-body text-left">
            <h5 className="card-heading">AeroForecast</h5>
            <p className="card-subheading">A deep learning based weather forecast model</p>
          </div>
        </div>

        <div className="card" onClick={() => null}>
          <div className="w-100">
          <img className="img-fluid" src={modularImg} alt="Blank Thumbnail"></img>
          </div>
          <div className="card-body text-left">
            <h5 className="card-heading">Modular {'(coming soon...)'}</h5>
            <p className="card-subheading">Redesigning the userflow for Modular</p>
          </div>
        </div>

      </div>
    </div>
  )
}
