import React from 'react'
import "./Navbar.css"

export default function Navbar() {
  return (
    <div>
        <nav className="navbar smart-scroll scrolled-up navbar-expand-lg">
            <a className="navbar-brand js-scroll-trigger" href="/">Charlene Chen</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link js-scroll-trigger" href="/#work">Work</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link js-scroll-trigger" href="/#cases">Case Studies</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link js-scroll-trigger" href="/#contacts">Contact</a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link js-scroll-trigger" href="/resume">Resume</a>
                    </li> */}
                </ul>
            </div>
        </nav>
    </div>
  )
}
